<template>
  <v-layout>
    <v-dialog
      :value="!reduced && uploads.length > 0 && globalProgression < 100"
      persistent
      scrollable
      :max-width="$vuetify.breakpoint.mdAndUp ? '40vw' : '90vw'"
      hide-overlay
      max-height="80vh"
    >
      <v-card>
        <v-card-title :class="reduced ? 'secondary py-0 my-0' : 'secondary'">
          <v-layout row justify-center align-center white--text>
            <v-flex v-t="'ecm.upload_progression'" headline></v-flex>
            <v-spacer></v-spacer>
            <v-btn icon @click="reduceDialog()">
              <v-icon class="white--text">far fa-window-minimize</v-icon>
            </v-btn>
          </v-layout>
        </v-card-title>
        <v-card-text v-if="!reduced">
          <v-container fluid grid-list-sm>
            <v-layout column>
              <v-flex scroll-y>
                <v-list>
                  <v-list-tile v-for="upload in uploads" :key="upload.id">
                    <v-list-tile-title>
                      <v-layout justify-center align-center row>
                        <v-flex xs6 text-xs-left text-truncate v-text="upload.name" />
                        <v-flex xs5>
                          <v-progress-linear
                            v-model="upload.progression"
                            :size="20"
                            color="primary"
                          ></v-progress-linear>
                        </v-flex>
                        <v-flex xs1>{{ upload.progression + "%" }}</v-flex>
                      </v-layout>
                    </v-list-tile-title>
                  </v-list-tile>
                </v-list>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
      :value="reduced && uploads.length > 0 && globalProgression <= 100"
      top
      :timeout="0"
      color="info"
    >
      <v-layout row justify-center align-center>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-progress-linear
              :value="globalProgression"
              color="white"
              class="info--text subheading font-weight-bold"
              height="1.5em"
              v-on="on"
            >
              {{ globalProgression + "%" }}
            </v-progress-linear>
          </template>
          <span v-t="'ecm.upload_progression'" />
        </v-tooltip>
        <v-btn icon tile @click="maximizeSnackbar()">
          <v-icon class="white--text" small>far fa-window-maximize</v-icon>
        </v-btn>
      </v-layout>
    </v-snackbar>
  </v-layout>
</template>

<script>
import AppModuleGuard from "@/mixins/ModulesGuards/AppModuleGuard"

export default {
  name: "UploadProgress",
  mixins: [AppModuleGuard],
  data: function () {
    return {
      reduced: false,
      progressTotal: {},
      progressDone: {},
      uploadsCache: [],
      uploads: [],
      confirmMessage: null
    }
  },
  computed: {
    globalProgression: function () {
      return Math.round((this.totalSizeUploaded / this.totalSizeToUpload) * 100)
    },
    totalSizeUploaded: function () {
      return this.uploadsCache.reduce(
        (acc, value) => acc + value.size * (value.progression / 100),
        0
      )
    },
    totalSizeToUpload: function () {
      return this.uploadsCache.reduce((acc, value) => acc + value.size, 0)
    },
    uploadsInProgress: function () {
      return this.uploads?.length > 0 || this.globalProgression < 100
    }
  },
  mounted: function () {
    this.reduced = localStorage.getItem("uploadProgress_preference") === "snackbar"
    window.onbeforeunload = function () {
      if (this.uploadsInProgress) {
        return this.$tc('upload.unload', this.uploads.length)
      }
    }.bind(this)
  },
  methods: {
    getAppEventsActionsMapping: function () {
      return [
        { event: this.appEvents.ADD_UPLOAD_PROGRESS, action: this.onAddUploadProgress },
        {
          event: this.appEvents.REMOVE_UPLOAD_PROGRESS,
          action: this.onRemoveUploadProgress,
        },
      ]
    },
    addUploadItem: function (uploadItem) {
      this.uploads.push(uploadItem)
      this.uploadsCache.push(uploadItem)
    },
    maximizeSnackbar: function () {
      localStorage.setItem("uploadProgress_preference", "dialog")
      this.reduced = false
    },
    onAddUploadProgress: function (uploadItem) {
      const uploadItemIndex = this.uploads.findIndex((item) => item.id === uploadItem.id)

      if (uploadItemIndex === -1) {
        this.addUploadItem(uploadItem)
      }
    },
    onRemoveUploadProgress: function (uploadItemId) {
      const uploadItemIndex = this.uploads.findIndex((item) => item.id === uploadItemId)

      if (uploadItemIndex !== -1) {
        this.uploads.splice(uploadItemIndex, 1)
      }

      if (this.uploads.length === 0) {
        this.uploadsCache.clear()
      }
    },
    reduceDialog: function () {
      localStorage.setItem("uploadProgress_preference", "snackbar")
      this.reduced = true
    },
  },
}
</script>
